@font-face {
   font-family: RedRose;
   src: url(/RedRose-Regular.ttf);
}

* {
   font-family: 'RedRose';
}

.contenedor{
   position: relative;
   /* display: inline-block; */
   text-align: center;
}

.texto-encima{
   position: absolute;
   top: 10px;
   left: 10px;
}
.centrado{
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.azulBoton{
   background-color: #04b4f4;
}

.azulFranja{
   background-color: #306dd2;
}

.verdeWhatsApp{
   background-color: #1bd443;
}

.corazon{
   color: red;
}

.tarjeta {
   box-shadow: 5px 5px 15px #4b4b4b;
}

a.menu {
   border-style: solid;
   border-width: 4px;
   border-color: #a6dfdf;
   background-color: #60d2d0;
   box-shadow: -3px 3px 5px white inset;
}

a.active {
   background-color: #3182ce;
   box-shadow: 0px 0px 10px white;
}
